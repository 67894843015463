import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { FormattedMessage } from 'react-intl';
import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  labelTip: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    hint: PropTypes.node,
  })).isRequired,
  hint: PropTypes.node,
  showToDestroyResponse: PropTypes.bool,
};

const defaultProps = {
  className: null,
  label: null,
  labelTip: null,
  hint: null,
  showToDestroyResponse: true,
};

function RenderRadioFields(props) {
  const {
    input: { onChange, name, value }, meta,
    className, label, labelTip, hint, options,
    showToDestroyResponse,
  } = props;

  return (
    <div className={className}>
      <div className="label-cnt">
        <Label label={label} tip={labelTip} />
        {hint && <span className="hint">{hint}</span>}
      </div>
      <div className="radios-container">
        <div className="radios">
          {
            options.map((option) => {
              const id = option.value + name;

              return (
                <React.Fragment key={id}>
                  <div className="form-radio">
                    <input
                      name={name}
                      id={id}
                      onChange={onChange}
                      type="radio"
                      value={option.value}
                      checked={option.value.toString() === value.toString()}
                    />
                    <label htmlFor={id} title={option.label}>
                      {option.label}
                    </label>
                  </div>
                  {option.hint && <span className="hint">{option.hint}</span>}
                </React.Fragment>
              );
            })
          }
        </div>
        {
          showToDestroyResponse &&
            <div className="reset-link-cnt">
              <span onClick={() => onChange([])} className="reset-link link">
                <Icon name="huge-cancel-circle" />
                <FormattedMessage id="candidates.delete_answer" />
              </span>
            </div>
        }
        <ErrorMessage {...meta} />
      </div>
    </div>
  );
}

RenderRadioFields.propTypes = propTypes;
RenderRadioFields.defaultProps = defaultProps;

export default RenderRadioFields;
