import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import Select from 'react-select';
import { customStyles } from 'utils/helpers';

import { post } from 'utils/api/fetch-client';
import view from 'job-centers/containers/View';
import OfferList from './OfferList';
import VideoIframe from './VideoIframe';

const propTypes = {
  appsignal_key: PropTypes.string,
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  referrer_source: PropTypes.string,
};

const defaultProps = {
  appsignal_key: '',
  referrer_source: null,
};

class IndexContent extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCounty = this.handleChangeCounty.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeSite = this.handleChangeSite.bind(this);
    this.handleChangeSubcategory = this.handleChangeSubcategory.bind(this);
    this.handleChangeAggregation = this.handleChangeAggregation.bind(this);

    this.state = {
      jobs: null,
      counties: [],
      countries: [],
      cities: [],
      subcategories: [],
      aggregations: [],
      sites: [],
      countyId: null,
      countryId: null,
      cityId: null,
      siteId: null,
      aggregationId: null,
      subcategory: null,
      headerImageUrl: null,
      headerImageMobileUrl: null,
      settings: {},
      pagingData: { page: 1, total: 1 },
    };
  }

  componentDidMount() {
    this.handleSubmit(null);
  }

  handleSubmit(page) {
    const {
      countyId,
      countryId,
      cityId,
      siteId,
      subcategory,
      aggregationId,
    } = this.state;

    const { intl } = this.props;
    const { locale } = intl;

    const url = Routes.search_custom_companies_headhunter_jobs_path();

    post(
      url,
      {
        page,
        locale,
        county_id: countyId,
        country_id: countryId,
        city_id: cityId,
        site_ids: siteId,
        subcategory,
        aggregation_id: aggregationId,
      },
    ).then(data =>
      this.setState({
        jobs: data.jobs,
        totalJobs: data.total_jobs,
        totalVacancies: data.total_vacancies,
        counties: data.counties,
        countries: data.countries,
        cities: data.cities,
        subcategories: data.subcategories,
        aggregations: data.aggregations,
        sites: data.sites,
        pagingData: data.paging_data,
        headerImageUrl: data.header_image_url,
        headerImageMobileUrl: data.header_image_mobile_url,
        settings: data.settings,
        corporateTitle: data.corporate_title,
        jobCenterDescription: data.job_center_description,
        jobListText: data.job_list_text,
      }));
  }

  handleChangeCounty(val) {
    this.setState({
      countyId: val,
    }, () => this.handleSubmit(1));
  }

  handleChangeCountry(val) {
    this.setState({
      countryId: val,
    }, () => this.handleSubmit(1));
  }

  handleChangeCity(val) {
    this.setState({
      cityId: val,
    }, () => this.handleSubmit(1));
  }

  handleChangeSite(val) {
    this.setState({
      siteId: val,
    }, () => this.handleSubmit(1));
  }

  handleChangeSubcategory(val) {
    this.setState({
      subcategory: val,
    }, () => this.handleSubmit(1));
  }

  handleChangeAggregation(val) {
    this.setState({
      aggregationId: val,
    }, () => this.handleSubmit(1));
  }

  renderDescription(jobCenterDescription, corporateTitle, color = '') {
    return (
      <div className="desc-cnt">
        <div className="title" style={{ color }}>
          {corporateTitle || <FormattedMessage id="jobs.jobcenter_description" />}
        </div>
        <div className="description">
          <div
            dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
              __html: jobCenterDescription,
            }}
          />
        </div>
      </div>
    );
  }

  renderVideo(videoUrl) {
    if (videoUrl) {
      return (
        <div className="video-inner">
          <VideoIframe videoUrl={videoUrl} />
        </div>
      );
    }

    return undefined;
  }

  renderHeaderOptions(displayOptions, corporateTitle, jobCenterDescription, videoUrl, color) {
    switch (displayOptions) {
      case 'description_video':
        return (
          <div className="job-index-description">
            {this.renderDescription(jobCenterDescription, corporateTitle, color)}
            { videoUrl && (
            <div className="video">
              {this.renderVideo(videoUrl)}
            </div>
            )}
          </div>
        );
      case 'description':
        return (
          <div className="job-index-description centered">
            {this.renderDescription(jobCenterDescription, corporateTitle, color)}
          </div>
        );
      case 'video':
        return (
          <div className="job-index-description centered">
            {this.renderVideo(videoUrl)}
          </div>
        );

      default:
        return undefined;
    }
  }

  renderFilters() {
    const {
      sites, siteId,
      counties, subcategories, countyId, subcategory,
      countries, aggregationId, aggregations,
      countryId, cities, cityId,
      settings: {
        province_filter: provinceFilter,
        site_filter: siteFilter,
        category_filter: categoryFilter,
        country_filter: countryFilter,
        city_filter: cityFilter,
        aggregation_filter: aggregationFilter,
      },
    } = this.state;

    return (
      <div className="job-index-filters">
        <div className="wrapper">
          <div className="filters">
            { siteFilter && sites && sites.length > 1 && (
            <div className="job-filters">
              <Select
                simpleValue
                autosize={false}
                name="site_ids"
                value={siteId}
                options={sites}
                isClearable
                onChange={this.handleChangeSite}
                autoBlur
                placeholder={<FormattedMessage id="jobs.site" />}
                styles={customStyles}
              />
            </div>
            )}
            { countryFilter && (
              <div className="job-filters">
                <Select
                  simpleValue
                  autosize={false}
                  name="country_id"
                  value={countryId}
                  options={countries}
                  isClearable
                  onChange={this.handleChangeCountry}
                  autoBlur
                  placeholder={<FormattedMessage id="jobs.country" />}
                  styles={customStyles}
                />
              </div>
            )}
            { provinceFilter && (
              <div className="job-filters">
                <Select
                  simpleValue
                  autosize={false}
                  name="county_id"
                  value={countyId}
                  options={counties}
                  isClearable
                  onChange={this.handleChangeCounty}
                  autoBlur
                  placeholder={<FormattedMessage id="jobs.province" />}
                  styles={customStyles}
                />
              </div>
            )}
            { cityFilter && (
              <div className="job-filters">
                <Select
                  simpleValue
                  autosize={false}
                  name="city_id"
                  value={cityId}
                  options={cities}
                  isClearable
                  onChange={this.handleChangeCity}
                  autoBlur
                  placeholder={<FormattedMessage id="jobs.city" />}
                  styles={customStyles}
                />
              </div>
            )}
            { categoryFilter && (
              <div className="job-filters">
                <Select
                  simpleValue
                  autosize={false}
                  name="subcategory"
                  value={subcategory}
                  options={subcategories}
                  isClearable
                  onChange={this.handleChangeSubcategory}
                  autoBlur
                  placeholder={<FormattedMessage id="jobs.category" />}
                  styles={customStyles}
                />
              </div>
            )}
            { aggregationFilter && (
              <div className="job-filters">
                <Select
                  simpleValue
                  autosize={false}
                  name="aggregation_id"
                  value={aggregationId}
                  options={aggregations}
                  isClearable
                  onChange={this.handleChangeAggregation}
                  autoBlur
                  placeholder={<FormattedMessage id="jobs.aggregation" />}
                  styles={customStyles}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      appsignal_key: appsignalKey,
      referrer_source: referrerSource,
    } = this.props;
    const {
      jobs,
      totalJobs, totalVacancies, jobListText,
      settings: {
        video_url: videoUrl,
        color,
        display_options: displayOptions,
        external_job_center_url: jobCenterUrl,
        show_total_jobs: showTotalJobs,
        show_total_vacancies: showTotalVacancies,
        show_aggregation: showAggregation,
        show_title: showTitle,
      },
      settings,
      headerImageUrl,
      headerImageMobileUrl,
      corporateTitle,
      jobCenterDescription,
      pagingData,
    } = this.state;
    const appsignal = new Appsignal({
      key: appsignalKey,
    });

    return (
      <ErrorBoundary
        instance={appsignal}
      >
        <div className="jobs">
          {
            (headerImageUrl || headerImageMobileUrl) &&
              <div className={`job-index-header ${headerImageUrl ? '' : 'only-mobile'}`}>
                <picture>
                  <source srcSet={headerImageMobileUrl} media="(max-width: 600px)" />
                  <img src={headerImageUrl} />
                </picture>
                <a href={jobCenterUrl}>
                  {jobCenterUrl}
                </a>
              </div>
          }
          <div className="wrapper">
            {
              this.renderHeaderOptions(
                displayOptions,
                corporateTitle,
                jobCenterDescription,
                videoUrl,
                color,
              )
            }
          </div>
          {
            jobs && jobs !== null && jobs.length > 0 &&
              <OfferList
                handleSubmit={this.handleSubmit}
                settings={settings}
                totalJobs={totalJobs}
                totalVacancies={totalVacancies}
                jobListText={jobListText}
                jobs={jobs}
                filters={this.renderFilters()}
                referrerSource={referrerSource}
                pagingData={pagingData}
                showTotalJobs={showTotalJobs}
                showTotalVacancies={showTotalVacancies}
                showAggregation={showAggregation}
                showTitle={showTitle}
              />
          }
          {
            jobs && jobs !== null && jobs.length === 0 &&
              <p className="centered">
                <FormattedMessage id="jobs.empty_job" />
              </p>
          }
        </div>
      </ErrorBoundary>
    );
  }
}

IndexContent.propTypes = propTypes;
IndexContent.defaultProps = defaultProps;

export default view(injectIntl(IndexContent));
