import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from 'common-components/Icon';
import PropTypes from 'prop-types';
import Pagination from 'common-components/Pagination';
import careerSiteImage from 'images/site/list_image_default.png';

import { hex2rgb } from 'utils/helpers';

const propTypes = {
  handleSubmit: PropTypes.func,
  jobs: PropTypes.arrayOf(PropTypes.shape()),
  jobCenterUrl: PropTypes.string,
  totalJobs: PropTypes.number,
  totalVacancies: PropTypes.number,
  jobListText: PropTypes.string,
  referrerSource: PropTypes.string,
  pagingData: PropTypes.shape().isRequired,
  settings: PropTypes.shape(),
  filters: PropTypes.shape(),
  showTitle: PropTypes.bool.isRequired,
  showTotalJobs: PropTypes.bool.isRequired,
  showTotalVacancies: PropTypes.bool.isRequired,
  showAggregation: PropTypes.bool.isRequired,
  fromPage: PropTypes.bool,
};

const defaultProps = {
  jobs: [],
  totalJobs: 0,
  totalVacancies: 0,
  jobListText: undefined,
  referrerSource: undefined,
  jobCenterUrl: undefined,
  settings: {
  },
  filters: null,
  handleSubmit: undefined,
  fromPage: false,
};

const listThemes = ['list', 'list_logo', 'list_image'];
const themeClassHash = {
  list_logo_button: 'list-logo-button',
  list: 'list',
  list_logo: 'list-logo',
  list_image: 'list-image',
  grid: 'triple',
  grid_image: 'triple-with-image',
  grid_small_image: 'triple-with-small-image',
};

class OfferList extends React.Component {
  constructor(props) {
    super(props);

    this.renderJobs = this.renderJobs.bind(this);
    this.renderTotalJobVacanciesMessage = this.renderTotalJobVacanciesMessage.bind(this);
  }

  componentDidMount() {
    let currentDocumentHeight = 0;

    const sendMessageUpdatingHeight = (height) => {
      window.parent.postMessage({ eventName: 'SET_HEIGHT', payload: { height } }, '*');
    };

    const documentHeight = document.getElementById('content').offsetHeight;

    if (documentHeight && documentHeight !== currentDocumentHeight) {
      currentDocumentHeight = documentHeight;
      sendMessageUpdatingHeight(documentHeight);
    }
  }

  renderThemeJob(theme, job) {
    switch (theme) {
      case 'list':
      case 'grid':
        return null;
      case 'list_logo_button':
      case 'list_logo':
      case 'list_image':
        return (this.renderListLogo(job));
      case 'grid_image':
      case 'grid_small_image':
        return (this.renderGridImage(job));
      default:
        return (this.renderListLogo(job));
    }
  }

  renderGridImage(job) {
    const {
      list_image_url: jobImageUrl,
      site: {
        list_image_url: siteListImageUrl,
        image_url: siteImageUrl,
      },
    } = job;
    return (
      <div className="image-wrapper">
        <img src={jobImageUrl || siteListImageUrl || siteImageUrl || careerSiteImage} alt="" />
        <div className="hover-layer">
          <span className="text">
            <FormattedMessage id="jobs.job_centers.show_offer" />
          </span>
        </div>
      </div>
    );
  }

  renderListLogo(job) {
    const {
      list_image_url: jobImageUrl,
      site: {
        list_image_url: siteListImageUrl,
        image_url: siteImageUrl,
      },
    } = job;
    return (
      <div className="logo-wrapper">
        <div className="company-logo-franchise">
          <img src={jobImageUrl || siteListImageUrl || siteImageUrl || careerSiteImage} alt="logo" />
        </div>
      </div>
    );
  }

  renderTotalJobVacanciesMessage() {
    const {
      totalJobs, totalVacancies,
      jobListText,
      showTitle,
      showTotalJobs,
      showTotalVacancies,
      settings: {
        color,
      },
    } = this.props;

    const jobsMessage = <b><FormattedMessage id="jobs.job_centers.total_jobs" values={{ totalJobs }} /></b>;
    const vacanciesMessage = <b><FormattedMessage id="jobs.job_centers.total_vacancies" values={{ totalVacancies }} /></b>;

    return (
      <div className="job-counters">
        <div className="wrapper">
          <p className="title" style={{ color: color || '' }}>
            {showTitle && (jobListText || <FormattedMessage id="jobs.job_offers" />)}
          </p>
          { (showTotalJobs && !showTotalVacancies) && (
          <p>
            <FormattedMessage id="jobs.job_centers.total_message" values={{ message: jobsMessage }} />
          </p>
          )}
          { (!showTotalJobs && showTotalVacancies) && (
            <p>
              <FormattedMessage id="jobs.job_centers.total_message" values={{ message: vacanciesMessage }} />
            </p>
          )}
          { (showTotalJobs && showTotalVacancies) && (
            <p>
              <FormattedMessage id="jobs.job_centers.jobs_and_vacancies_message" values={{ jobsMessage, vacanciesMessage }} />
            </p>
          )}
        </div>
      </div>
    );
  }

  renderJobs(theme) {
    const {
      jobs,
      referrerSource,
      showAggregation,
      settings: {
        show_logo: showLogo,
        show_all_jobs: showAllJobs,
      },
    } = this.props;

    const suffix = (showAllJobs || showLogo) ? '-franchise' : '';

    return jobs.map((job) => {
      const city = job.regions.length ? job.regions[0].name : '-';
      const { category } = job;
      const hideCategory = job.job_setting.hide_detail_box;
      let url = `${window.location.protocol}//${job.site_host}/jobs/${job.slug}`;
      if (referrerSource) {
        url += `?referrer_source=${referrerSource}`;
      }

      const hideLocation = job.job_setting.hide_location;

      return (
        <React.Fragment key={job.id}>
          <style>
            {`.jobs .jobs-list .job.job-${job.id} .job-link-wrapper:hover .job-title,
              .jobs .jobs-list .job.job-${job.id} .job-link-wrapper:hover .arrowbtn {
              color: ${job.site.color}
            }
            .jobs .jobs-list.triple-with-image .job.job-${job.id} .image-wrapper .hover-layer,
            .jobs .jobs-list.triple-with-small-image .job.job-${job.id} .image-wrapper .hover-layer{
              background: rgba(${hex2rgb(job.site.color)}, .8);
            }
            `}
          </style>
          <div className={`job job-${job.id}`}>
            <a
              className="job-link-wrapper"
              rel="noopener noreferrer"
              target="_blank"
              href={url}
            >
              <div className={`wrapper-title${suffix}`}>
                {this.renderThemeJob(theme, job)}
                <div className="wrapper-info-franchise">
                  <div className="job-category">
                    {(category && !hideCategory) &&
                      <FormattedMessage id={`shared.job_data.categories.${category}.__name__`} />}
                  </div>
                  <div className={`link-title${suffix} job-title`}>
                    <div
                      className={`job-title${suffix}`}
                      title={job.title}
                    >
                      {job.title}
                    </div>
                  </div>
                  {
                    !hideLocation && (
                      <div className={`job-location${suffix} job-location`} title={city}>
                        {city}
                      </div>
                    )
                  }
                  {(showAggregation && job.aggregation) && (
                    <div className="job-aggregation">
                      {job.aggregation.name}
                    </div>
                  )}
                </div>
                { theme === 'list_logo_button' && (
                  <div
                    className="button button--primary"
                    style={{
                      backgroundColor: job.site.color,
                      borderColor: job.site.color,
                    }}
                  >
                    <FormattedMessage id="jobs.apply_for" />
                  </div>
                )}
                { listThemes.includes(theme) &&
                  <Icon name="huge-circle-arrow-right-01" klass="arrowbtn" />}
              </div>
            </a>
          </div>
        </React.Fragment>
      );
    });
  }

  render() {
    const {
      jobs,
      filters,
      jobCenterUrl, handleSubmit,
      settings: {
        color,
        theme,
        corporate_color: corporateColor,
        transparent_background: transparentBackground,
        show_footer: showFooter,
      },
      pagingData: {
        page,
        total,
      },
      fromPage,
    } = this.props;

    const themeClass = themeClassHash[theme] || '';

    return (
      <div className="jobs">
        <style>
          {`.pagination button:not(.prev):not(.next):hover:not(.active),
            .pagination a:not(.prev):not(.next):hover:not(.active),
            .pagination span:not(.ellipsis):hover:not(.active) {
              border-color: ${color};
              background-color: ${color};
            }
            button:not(.prev):not(.next):not(.active),
            a:not(.prev):not(.next):not(.active):not(.ccb__edit),
            span:not(.ellipsis):not(.active),
            .pagination .next,
            .pagination .prev {
              color: ${color};
            }
            .cc-text a {
              color: inherit !important;
            }
            .jobs .jobs-list .action .button {
              color: ${color};
            }
            .iframe-wrapper {
              background: ${transparentBackground ? 'transparent' : corporateColor};
            }
          `}
        </style>
        { jobs && jobs !== null && (
          <div className={`iframe-wrapper ${showFooter && !fromPage ? 'with-footer' : ''}`}>
            {this.renderTotalJobVacanciesMessage()}
            {filters}
            <div className={`jobs-list ${themeClass}`}>
              <div className="wrapper">
                {this.renderJobs(theme)}
                {jobCenterUrl ? (
                  <div className="action centered">
                    <a
                      className="button"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={jobCenterUrl}
                    >
                      <FormattedMessage id="jobs.show_all_offers" />
                    </a>
                  </div>
                )
                  : (
                    <Pagination
                      currentPage={page}
                      totalPages={total}
                      onPaginationClick={handleSubmit}
                    />
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

OfferList.propTypes = propTypes;
OfferList.defaultProps = defaultProps;
export default injectIntl(OfferList);
